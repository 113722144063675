import React, { useContext } from 'react';
import styled from 'styled-components';
import { HubEventsHeader, HubFooter, SEO, PastEvents, Faq, OurSponsor, Terms } from 'components';
import { LocalContext } from 'context';
import { useEventData } from 'hooks';

const EventsHub = () => {
  const { edges: events } = useEventData();
  const { theme } = useContext(LocalContext);

  const pastEvents = events.filter(
    ({ node }) => node.frontmatter.status === 'past' && !node.frontmatter.hidden
  );

  return (
    <>
      <SEO pageSpecificTitle="Events" />
      <HubEventsHeader />
      <OurSponsor />
      {pastEvents.length > 0 && (
        <Container bg="white">
          <Section $theme={theme}>
            <h2>Watch Our Past Events</h2>
            <PastEvents />
          </Section>
        </Container>
      )}
      <Container>
        <Section $theme={theme}>
          <Faq />
        </Section>
      </Container>
      {/* <Terms /> */}
      <HubFooter />
    </>
  );
};

const Section = styled.section`
  grid-column: 1/7;
  margin: 3rem 0;
  h2 {
    color: ${({ $theme }) => $theme.muted};
    font-family: 'Open-Sans', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0 auto;
    margin-bottom: 3rem;
    max-width: 100%;
    text-align: center;
    width: 800px;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    p {
      margin-bottom: 4rem;
    }
  }
`;

const Container = styled.section`
  align-items: flex-start;
  background-color: ${({ bg }) => bg};
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 3rem 1rem;
  @media (min-width: 768px) {
    padding: 3rem 1.25rem;
  }
  @media (min-width: 1150px) {
    padding: 3rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default EventsHub;
